import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Flex,
  Input,
  SPACING,
  Typography,
  COLORS,
  Space,
  FONTS,
  Upload,
  UploadProps,
  Modal,
  Image,
  RADIUS,
  Radio,
  RadioGroup,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import { AudioOutlined, CameraOutlined, SendOutlined } from '@ant-design/icons';
import {
  FILE_SIZE_LIMIT,
  IMAGE_EXTENSIONS,
  New,
  useAnalyzeImageMutation,
  useStoreFileMutation,
} from '@optii/global';
import { Session } from '@optii/shared';

const BUTTON_STYLE: CSSProperties = {
  color: COLORS.primary[5],
  padding: SPACING.SIZE_MS,
};

const SUB_TEXT_STYLE: CSSProperties = {
  fontSize: FONTS.xSmall.size,
  letterSpacing: FONTS.xSmall.letterSpacing,
  fontWeight: 500,
  color: COLORS.neutral[8],
};

const INFO_STYLE: CSSProperties = {
  color: COLORS.neutral[8],
  fontWeight: 500,
  letterSpacing: FONTS.medium.letterSpacing,
};

const INFO_CONTAINER_STYLE: CSSProperties = {
  backgroundColor: COLORS.secondary[1],
  paddingInline: SPACING.SIZE_SM,
  border: `1px solid ${COLORS.secondary[2]}`,
  marginBottom: SPACING.SIZE_MD,
};

const LABEL_STYLE: CSSProperties = {
  color: COLORS.neutral[8],
  letterSpacing: FONTS.medium.letterSpacing,
  fontWeight: 500,
};

const INPUT_EXAMPLE_STYLE: CSSProperties = {
  color: COLORS.neutral[7],
  letterSpacing: FONTS.xSmall.letterSpacing,
  fontSize: FONTS.xSmall.size,
};

const ASSISTANT_CONTAINER_STYLE: CSSProperties = {
  paddingInline: SPACING.SIZE_MD,
  paddingBlock: SPACING.SIZE_MS,
  backgroundColor: COLORS.neutral[2],
  marginBottom: SPACING.SIZE_MD,
};

const SUGGESTION_VALUE_STYLE: CSSProperties = {
  fontSize: FONTS.medium.size,
  letterSpacing: FONTS.medium.letterSpacing,
  fontWeight: 400,
  color: COLORS.neutral[8],
};
const SUGGESTION_LABEL_STYLE: CSSProperties = {
  fontSize: FONTS.small.size,
  letterSpacing: FONTS.small.letterSpacing,
  color: COLORS.neutral[8],
  fontWeight: 500,
};

type Suggestion = {
  [key: string]: string[] | number[];
};

const SUGGESTION_LABELS = {
  0: 'Action',
  1: 'Location',
  2: 'Description',
  3: 'Score',
  4: 'Job Item',
};

const { Text, Title } = Typography;

type Props = {
  assistantDescription: string;
  setAssistantDescription: Dispatch<SetStateAction<string>>;
};

export function Assistant({
  assistantDescription,
  setAssistantDescription,
}: Props) {
  const { t } = useTranslation(['common', 'jobs']);
  const { globalSnack } = useContext(Session);
  const [openSuggestionModal, setOpenSuggestionModal] = useState(false);
  const [analyzedImage, setAnalyzedImage] = useState<string | undefined>('');
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>('');
  const [fileList, setFileList] = useState([]);

  const [
    analyzeImage,
    { loading: analyzeImageLoading, data: possibleSuggestions },
  ] = useAnalyzeImageMutation({
    context: {
      _instance: 'node',
    },
  });

  const formattedSuggestions = useMemo(() => {
    if (possibleSuggestions) {
      const { result } = possibleSuggestions?.analyzeImage as {
        result: Suggestion;
      };

      const formattedOptions = Object.keys(result)
        .map((item) => ({
          label: item,
          value: item,
          extra: result[item] as string[],
        }))
        .sort((a, b) => {
          const scoreA = result[a.value][3];
          const scoreB = result[b.value][3];

          if (typeof scoreA === 'number' && typeof scoreB === 'number')
            return scoreA - scoreB;

          return 1;
        });

      setSelectedSuggestion(formattedOptions[0]?.label);

      return formattedOptions;
    }
    return [];
  }, [possibleSuggestions]);

  const [storeFile, { loading: storeFileLoading }] = useStoreFileMutation({
    onError(error) {
      console.error(error);
      return globalSnack({
        message: t('jobs:Could not upload attachment'),
        timeout: 5000,
        error: true,
      });
    },
  });

  const uploadFile = useCallback(
    async (file: Blob) => {
      if (file.size >= FILE_SIZE_LIMIT)
        return globalSnack({
          message: t(
            'common:This file is too large, please provide a file under 20MB',
          ),
          error: true,
          timeout: 5000,
        });

      const { data } = await storeFile({
        variables: {
          file,
          name: file.name,
        },
      });

      return data?.file.URL;
    },
    [globalSnack, storeFile, t],
  );

  const customRequest: UploadProps['customRequest'] = async (options) => {
    const { onSuccess, file } = options;

    if (typeof onSuccess === 'function') {
      const imagePath = await uploadFile(file as Blob);

      if (imagePath)
        await analyzeImage({
          variables: {
            imagePath: [imagePath],
            text: assistantDescription || '',
          },
          onCompleted() {
            setOpenSuggestionModal(true);
            setAnalyzedImage(imagePath);
          },
          onError(error) {
            console.error(error);

            globalSnack({
              message: t(
                'common:The image does not possess any hotel related items, please try again with a hotel related image',
              ),
              error: true,
              timeout: 5000,
            });
          },
        });

      onSuccess(imagePath);
    }
  };

  function onCancel() {
    setOpenSuggestionModal(false);
  }

  return (
    <Space style={ASSISTANT_CONTAINER_STYLE}>
      <Title />

      <Flex vertical>
        <Flex gap={SPACING.SIZE_SM} align="center" style={INFO_CONTAINER_STYLE}>
          <New />
          <Text style={INFO_STYLE}>
            {t(
              'jobs:Upload a photo or enter a job description, using text or voice',
            )}
          </Text>
        </Flex>

        <Flex vertical>
          <Text style={LABEL_STYLE}>{t('common:Job Description')}</Text>
          <Flex align="center" gap={SPACING.SIZE_SM}>
            <Input
              allowClear
              size="large"
              value={assistantDescription}
              onChange={({ target: { value } }) =>
                setAssistantDescription(value)
              }
            />
            <Button
              icon={assistantDescription ? <SendOutlined /> : <AudioOutlined />}
              size="large"
              type="default"
              style={BUTTON_STYLE}
            />

            <Upload
              type="select"
              multiple
              showUploadList={false}
              accept={IMAGE_EXTENSIONS.join(',')}
              customRequest={customRequest}
            >
              <Button
                icon={<CameraOutlined />}
                loading={storeFileLoading || analyzeImageLoading}
                size="large"
                type="default"
                style={BUTTON_STYLE}
              />
            </Upload>
          </Flex>
        </Flex>
        <Text style={INPUT_EXAMPLE_STYLE}>
          {t(
            "jobs:Ex, Replace the overhead light's lightbulb in room 421 by 1:31",
          )}
        </Text>
      </Flex>
      <Modal
        title="Suggestions"
        open={openSuggestionModal}
        onClose={onCancel}
        onCancel={onCancel}
        centered
      >
        <Flex gap={SPACING.SIZE_MD} vertical>
          {analyzedImage ? (
            <Image
              src={analyzedImage}
              width={125}
              wrapperStyle={{
                border: `1px solid ${COLORS.neutral[5]}`,
                borderRadius: RADIUS.RADIUS_L,
                alignSelf: 'center',
              }}
            />
          ) : null}

          <Flex vertical gap={SPACING.SIZE_XS}>
            <Typography.Text style={SUB_TEXT_STYLE}>
              Please select one of the following suggestions:
            </Typography.Text>
            <RadioGroup
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              onChange={(e) => {
                setSelectedSuggestion(e.target.value);
              }}
              defaultValue={selectedSuggestion}
              options={formattedSuggestions.map(({ label, value }) => ({
                label,
                value,
              }))}
            >
              {formattedSuggestions.map((item) => (
                <Radio>{item.label}</Radio>
              ))}
            </RadioGroup>
          </Flex>

          <Typography.Text style={SUB_TEXT_STYLE}>
            {t('jobs:The following information will be added to the Job')}:
          </Typography.Text>

          {selectedSuggestion
            ? formattedSuggestions
                .find((item) => item.value === selectedSuggestion)
                ?.extra.map((item, index) => (
                  <Flex
                    justify="flex-start"
                    align="baseline"
                    gap={SPACING.SIZE_XS}
                  >
                    <Typography.Text style={SUGGESTION_LABEL_STYLE}>
                      {
                        SUGGESTION_LABELS[
                          index as keyof typeof SUGGESTION_LABELS
                        ]
                      }
                      :
                    </Typography.Text>
                    <Typography.Text style={SUGGESTION_VALUE_STYLE}>
                      {item || '-'}
                    </Typography.Text>
                  </Flex>
                ))
            : null}
        </Flex>
      </Modal>
    </Space>
  );
}
